@import '../../../../scss/theme-bootstrap';

.gnav-header-block {
  height: $gnav-mobile-height;
  position: relative;
  .content-block-sitewide-banner--enabled & {
    height: $gnav-mobile-height + $sitewide-banner-mobile-height;
  }
  @include breakpoint($landscape-up) {
    height: $gnav-height;
    .content-block-sitewide-banner--enabled & {
      height: $gnav-height + $sitewide-banner-height;
    }
  }
  .gnav-offers-hidden:not(.content-block-sitewide-banner--enabled) &,
  &.gnav-header-block--sticky {
    height: $navbar-height;
    @include breakpoint($landscape-up) {
      height: $menu-height;
    }
  }
  .gnav-offers-hidden.content-block-sitewide-banner--enabled & {
    height: $navbar-height + $sitewide-banner-mobile-height;
    @include breakpoint($landscape-up) {
      height: $menu-height + $sitewide-banner-height;
    }
  }
  &__inner {
    @include transition(transform 0.25s ease);
    background-color: $color-cream;
    position: fixed;
    top: auto;
    width: 100%;
    z-index: 900;
    .gnav-header-block--sticky & {
      z-index: 901;
    }
    // Hide the sticky Nav when scrolling down
    .gnav-header-block--hidden .gnav-header-block--sticky & {
      @include transform(translateY(-100%));
    }
    .gnav-util-overlay-active & {
      @include breakpoint($landscape-up) {
        z-index: 99999;
      }
      &.gnav-header-block--sticky {
        @include breakpoint($landscape-up) {
          z-index: 99999;
        }
      }
    }
  }
  &__top {
    @include transition(height 0.25s ease, opacity 0.25s ease);
    position: relative;
    height: 100%;
    opacity: 1;
    body:not(.content-block-sitewide-banner--enabled) .header-offers-banner-hidden &,
    .gnav-header-block--sticky & {
      height: 0;
      opacity: 0;
    }
  }
  &__header {
    @include swap_direction(padding, 0 $container-pad-small 0 $container-pad-small);
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
    width: 100%;
    @include breakpoint($landscape-up) {
      @include swap_direction(padding, 0 $container-pad-landscape 0 $container-pad-landscape);
    }
    .active-gnav & {
      padding-#{$ldirection}: 0;
      @include breakpoint($landscape-up) {
        padding-#{$ldirection}: $container-pad-landscape;
      }
    }
  }
  &__offers {
    max-width: 100%;
    overflow: hidden;
    width: 100%;
  }
  &__offers-below {
    .active-gnav & {
      display: none;
    }
  }
  &__bar {
    align-items: center;
    background-color: $color-cream;
    display: flex;
    height: $navbar-height;
    justify-content: space-between;
    position: relative;
    @include breakpoint($landscape-up) {
      flex-wrap: wrap;
      height: $menu-height;
    }
  }
  &__logo {
    display: block;
    #{$ldirection}: calc(50% - 55px);
    position: absolute;
    width: 110px;
    @include breakpoint($landscape-up) {
      top: 24px;
      width: auto;
    }
    svg {
      margin: 0 auto;
      height: 24px;
      width: 110px;
      @include breakpoint($landscape-up) {
        height: 28px;
        width: 134px;
      }
    }
  }
  // Mobile menu trigger
  &__menu-icon {
    cursor: pointer;
    order: 1;
    -webkit-tap-highlight-color: transparent;
    @include breakpoint($landscape-up) {
      display: none;
      padding: 0;
    }
    svg {
      height: $gnav-icon;
      width: $gnav-icon;
    }
  }
  &__tools {
    margin-#{$ldirection}: auto;
    order: 3;
    @include breakpoint($landscape-up) {
      margin-#{$ldirection}: 0;
      order: 1;
    }
  }
  &__utilities {
    order: 4;
    @include breakpoint($landscape-up) {
      order: 3;
    }
  }
  &__tools,
  &__utilities {
    display: flex;
    width: auto;
    @include breakpoint($medium-up) {
      margin-#{$rdirection}: 0;
    }
    @include breakpoint($landscape-up) {
      height: auto;
      margin-top: 18px;
      position: static;
    }
    .contextual-links-container {
      margin-top: -1em;
      position: absolute;
    }
    // @todo - this specificity is not necessary
    // this can be moved outside into its own block or properly nested
    .gnav-header-block__account,
    .gnav-header-block__wishlist,
    .gnav-header-block__cart,
    .gnav-header-block__stores {
      margin-#{$ldirection}: $container-pad-small;
      margin-top: 0;
      width: 21px;
      @include breakpoint($landscape-up) {
        width: 30px;
      }
      .icon {
        height: $gnav-icon;
        width: $gnav-icon;
      }
      .contextual-links-region {
        position: initial;
      }
    }
    .gnav-header-block__wishlist {
      .icon {
        height: 29px;
        width: 24px;
        top: -6px;
        position: relative;
      }
    }
    .gnav-header-block__account,
    .gnav-header-block__wishlist,
    .gnav-header-block__stores {
      display: none;
      @include breakpoint($landscape-up) {
        display: block;
      }
    }
    .gnav-header-block__stores {
      .gnav-util__icon--stores {
        @include breakpoint($landscape-up) {
          display: block;
        }
      }
    }
    .gnav-util__icon__cart-count,
    .gnav-util__icon-wishlist-count {
      color: $color-black;
      display: block;
      font-size: 10px;
      font-style: normal;
      height: 15px;
      line-height: 15px;
      position: absolute;
      text-align: center;
      margin-#{$ldirection}: 22px;
      top: 5px;
      @include breakpoint($landscape-up) {
        top: $navbar-offset;
      }
    }
    // Shared classes to be use in cart overlay and wishlist
    .gnav-util-overlay {
      width: 385px;
      background: $color-cream;
      #{$rdirection}: -#{$container-pad-landscape};
      opacity: 1;
      position: absolute;
      z-index: 99999;
      top: calc(#{$offerbar-height} + #{$navbar-offset});
      padding: 0;
      &-header {
        padding: 32px 44px;
      }
      &-bottom {
        padding: 32px 44px 48px;
      }
      &-title {
        @include title--h5;
        padding: 0;
      }
      &-content-no-items {
        padding: 0 44px 48px;
      }
    }
    .gnav-util-cart__content {
      overflow-y: auto;
    }
    .gnav-util__notification {
      color: $color-white;
      font-size: 14px;
      width: 85%;
      z-index: 999;
      position: fixed;
      #{$ldirection}: 50%;
      transform: translateX(-50%);
      top: 75px;
      @include breakpoint($landscape-up) {
        display: none;
      }
      &-close {
        @include swap_direction(padding, 15px 16px 15px 0);
        float: $rdirection;
        .icon--close {
          fill: $color-white;
          width: 22px;
          height: 22px;
        }
      }
      &-content {
        @include swap_direction(padding, 20px 0 20px 16px);
        float: $ldirection;
        line-height: get-line-height(12px, 12px);
        width: 85%;
        p {
          margin: 0;
        }
        a {
          color: $color-white;
          text-decoration: underline;
        }
      }
    }
    .gnav-util--wishlist,
    .gnav-util-cart,
    .gnav-account {
      height: 27px;
      @include breakpoint($landscape-up) {
        &:hover {
          height: 27px;
          &::before {
            content: '';
            width: 22px;
            height: 30px;
            border-bottom: 1px solid $color-black;
            position: absolute;
          }
        }
      }
    }
    .gnav-util-cart {
      cursor: pointer;
    }
  }
}

// Main Navigation - sections
.gnav-header-sections {
  @include swap_direction(padding, 0 0 0 $gnav-mobile-hpad--outer);
  z-index: 2; // keep mobile gnav clickable when js is off
  @include breakpoint($landscape-up) {
    align-items: center;
    display: flex;
    height: $navbar-height;
    justify-content: center;
    order: 4;
    padding: 0;
    width: 100%;
  }
  &__section--mobile-hidden {
    display: none;
    @include breakpoint($landscape-up) {
      display: flex;
    }
  }
  #gnav_mobile_trigger_input:checked ~ &,
  .active-gnav & {
    background-color: $color-cream;
    height: 100vh;
    position: fixed;
    top: 0;
    #{$ldirection}: 0;
    width: 86%;
    display: block;
    overflow-y: auto;
    z-index: $gnav-fixed-z-index;
    @include breakpoint($landscape-up) {
      display: flex;
      width: 100%;
    }
    &.section-expanded {
      overflow-y: inherit;
    }
  }
  @include breakpoint($landscape-up) {
    position: relative;
    .active-gnav & {
      background-color: transparent;
      overflow-y: inherit;
      height: $navbar-height;
      position: relative;
      top: auto;
    }
  }
  // Only displays for mobile, takes user back to main sections list
  &__mobile-menu-header {
    cursor: pointer;
    display: none;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-tap-highlight-color: transparent;
    &:hover {
      text-decoration: none;
    }
    span {
      display: inline-block;
      font-size: 12px;
      padding-#{$ldirection}: 4px;
    }
    .section-expanded & {
      display: block;
      font-weight: 700;
      height: $gnav-mobile-vpad--lg;
      line-height: $gnav-mobile-vpad--lg;
      margin: 0;
      @include breakpoint($landscape-up) {
        display: none;
      }
    }
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  &__mobile-menu-icon {
    display: inline-block;
    height: $gnav-icon--sm;
    width: $gnav-icon--sm;
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  &__section {
    font-family: $font--lars;
    font-size: 12px;
    width: 100%;
    @include breakpoint($landscape-up) {
      display: inline-block;
      width: auto;
    }
    &[aria-expanded='true'] {
      .section-expanded & {
        background-color: $color-white;
        height: 100vh;
        font-family: $font--lars-bold;
        #{$ldirection}: 0;
        overflow-y: auto;
        padding-bottom: $header-height;
        padding-#{$ldirection}: $gnav-mobile-hpad--outer;
        position: absolute;
        top: $gnav-mobile-vpad--lg;
        @include breakpoint($landscape-up) {
          background-color: transparent;
          height: auto;
          overflow-y: inherit;
          padding: 0;
          position: relative;
          top: auto;
        }
      }
    }
    &[aria-expanded='false'] {
      .section-expanded & {
        display: none;
        @include breakpoint($landscape-up) {
          display: inline-block;
        }
      }
    }
  }
  &__section-label {
    color: $color-black;
    cursor: pointer;
    display: block;
    font-weight: 700;
    height: $gnav-mobile-vpad--lg;
    line-height: $gnav-mobile-vpad--lg;
    padding-#{$rdirection}: 22px;
    text-decoration: none;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    @include breakpoint($landscape-up) {
      cursor: default;
      display: inline-block;
      font-weight: normal;
      height: auto;
      letter-spacing: get-letter-spacing(170);
      line-height: 16px; // Intentionally px based for the box shadow
      margin: 0 12px;
      padding-#{$rdirection}: 0;
    }
    @include breakpoint($xlarge-up) {
      margin: 0 24px;
    }
    &:hover {
      color: $color-black;
      text-decoration: none;
      @include breakpoint($landscape-up) {
        @include link-underline;
      }
    }

    .gnav-header-sections__section[aria-expanded='true'] & {
      @include breakpoint($landscape-up) {
        @include link-underline;
        text-shadow: 0 0 0.65px $color-black, 0 0 0.65px $color-black;
      }
    }

    // When a mobile child section is opened, overlay the main menu label
    &.gnav-mobile-group-label-active {
      @include breakpoint($landscape-down) {
        background-color: $color-cream;
        #{$ldirection}: 0;
        margin-top: -64px;
        padding-#{$ldirection}: $gnav-icon;
        position: fixed;
        text-transform: uppercase;
        width: 86%;
        z-index: 999;
        .gnav-sections-mobile-single & {
          display: none; // hide the expanded section trigger when only one section
        }
        .gnav-header-sections__mobile-section-toggle {
          .gnav-header-sections__section[aria-expanded='true'] & {
            background-image: url('/sites/jomalone2/themes/jomalone2_base/img/icons/src/chevron--left-v2.svg');
            position: absolute;
            top: 0;
            #{$ldirection}: 22px;
          }
        }
        label,
        span {
          display: inline-block;
          padding-#{$ldirection}: 25px;
        }
      }
    }
    @at-root a#{&} {
      cursor: pointer;
    }
  }

  &__links {
    @include breakpoint($landscape-up) {
      display: none;
    }
    &-link {
      font-family: $font--lars;
      font-size: 14px;
      height: $gnav-mobile-vpad--lg;
      line-height: $gnav-mobile-vpad--lg;
      a {
        text-decoration: none;
      }
    }
  }
  // Arrow displays for mobile expanders only.
  &__mobile-section-toggle {
    background: url('/sites/jomalone2/themes/jomalone2_base/img/icons/src/chevron-v2.svg') 0 50%
      no-repeat;
    background-size: $gnav-icon--sm $gnav-icon--sm;
    display: block;
    position: absolute;
    top: 0;
    #{$rdirection}: 22px;
    height: $gnav-mobile-vpad--lg;
    width: $gnav-icon--sm;
    transition: background 0.3s ease-in;
    .gnav-header-sections__section[aria-expanded='true'] & {
      background-image: none;
    }
    @include breakpoint($landscape-up) {
      background: none;
      display: none;
    }
  }
  &__section-content {
    background-color: $color-cream;
    display: none;
    height: 0;
    #{$ldirection}: 0;
    opacity: 0;
    position: absolute;
    visibility: hidden;
    width: 100%;
    z-index: $gnav-fixed-z-index;
    @include breakpoint($landscape-up) {
      @include transition(height 0.25s ease, opacity 0.25s ease, visibility 0.25s ease);
      top: $gnav-height;
      overflow: hidden;
      position: fixed;
      display: block;
      .content-block-sitewide-banner--enabled & {
        top: $gnav-height + $sitewide-banner-height - $navbar-offset;
      }
      .gnav-offers-below & {
        top: $gnav-height + $sitewide-banner-height - $offerbar-height;
      }
      .gnav-offers-below:not(.content-block-sitewide-banner--enabled) & {
        top: $gnav-height - $offerbar-height;
      }
    }
    // expanded state
    #gnav_section_1_input:focus ~ &,
    #gnav_section_2_input:focus ~ &,
    #gnav_section_3_input:focus ~ &,
    #gnav_section_4_input:focus ~ &,
    #gnav_section_5_input:focus ~ & {
      .gnav-section-group > div:nth-child(1) {
        .gnav-section-formatter__content-wrapper {
          @include breakpoint($landscape-up) {
            opacity: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
        }
        .gnav-section-formatter__link:hover ~ .gnav-section-formatter__content-wrapper {
          @include breakpoint($landscape-up) {
            opacity: 1;
          }
        }
      }
    }
    #gnav_section_1_input:checked ~ &,
    #gnav_section_2_input:checked ~ &,
    #gnav_section_3_input:checked ~ &,
    #gnav_section_4_input:checked ~ &,
    #gnav_section_5_input:checked ~ &,
    &[aria-hidden='false'] {
      display: block;
      height: 100%;
      opacity: 1;
      overflow-y: auto;
      position: relative;
      visibility: visible;
      @include breakpoint($landscape-up) {
        display: none;
        opacity: 0;
        position: fixed;
        visibility: hidden;
      }
    }
    #gnav_section_1_input:focus ~ &,
    #gnav_section_2_input:focus ~ &,
    #gnav_section_3_input:focus ~ &,
    #gnav_section_4_input:focus ~ &,
    #gnav_section_5_input:focus ~ &,
    &[aria-hidden='false'] {
      @include breakpoint($landscape-up) {
        display: block;
        opacity: 1;
        height: calc(#{100%} - #{175px});
        position: fixed;
        visibility: visible;
        z-index: $gnav-fixed-z-index;
      }
    }
    .gnav-header-block--sticky &,
    .gnav-offers-hidden:not(.content-block-sitewide-banner--enabled) & {
      @include breakpoint($landscape-up) {
        top: $menu-height - $navbar-offset;
      }
    }
    .gnav-offers-hidden.content-block-sitewide-banner--enabled & {
      @include breakpoint($landscape-up) {
        top: $menu-height + $sitewide-banner-height - $navbar-offset;
      }
    }

    // If mobile nav is open bg is white
    .section-expanded & {
      background-color: $color-white;
      overflow: hidden;
      @include breakpoint($landscape-up) {
        background-color: $color-cream;
        overflow: visible;
        height: auto;
      }
    }
  }
  &__background {
    @include transition(height 0.25s ease, opacity 0.25s ease, visibility 0.25s ease);
    background-color: $color-cream;
    height: 0;
    #{$ldirection}: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
    width: 100%;
    z-index: $gnav-fixed-z-index - 1;
    @include breakpoint($landscape-up) {
      top: $gnav-height;
      .content-block-sitewide-banner--enabled & {
        top: $gnav-height + $sitewide-banner-height;
      }
    }
    .active-gnav & {
      @include breakpoint($landscape-up) {
        height: auto;
        opacity: 1;
        visibility: visible;
      }
    }
    .active-gnav.search-overlay-displayed & {
      @include breakpoint($landscape-up) {
        @include transition(height 0.25s ease, opacity 0.25s ease, visibility 0.25s ease);
        height: 0;
      }
    }
    .gnav-header-block--sticky &,
    .gnav-offers-hidden:not(.content-block-sitewide-banner--enabled) & {
      @include breakpoint($landscape-up) {
        top: $menu-height;
      }
    }
    .gnav-offers-hidden.content-block-sitewide-banner--enabled & {
      @include breakpoint($landscape-up) {
        top: $menu-height + $sitewide-banner-height;
      }
    }
  }
}

// Grey overlay covers content when gnav is expanded
.gnav-header-overlay {
  background-color: $color-grey-transparent-50;
  cursor: pointer;
  display: block;
  height: 0;
  #{$ldirection}: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: visibility 0.3s, opacity 0.3s;
  visibility: hidden;
  width: 100%;
  z-index: $gnav-fixed-z-index - 2;
  #gnav_mobile_trigger_input:checked ~ &,
  .active-gnav & {
    height: 100vh;
    opacity: 1;
    visibility: visible;
    @include breakpoint($landscape-up) {
      top: $gnav-height;
    }
  }
  .active-gnav .content-block-sitewide-banner--enabled & {
    @include breakpoint($landscape-up) {
      top: $gnav-height + $sitewide-banner-height;
    }
  }
  .gnav-header-block--sticky &,
  .gnav-offers-hidden:not(.content-block-sitewide-banner--enabled) & {
    @include breakpoint($landscape-up) {
      top: $menu-height;
    }
  }
  .gnav-offers-hidden.content-block-sitewide-banner--enabled & {
    @include breakpoint($landscape-up) {
      top: $menu-height + $sitewide-banner-height;
    }
  }

  &--label {
    position: absolute;
    top: 0;
    #{$rdirection}: 5px;
    svg {
      fill: $color-white;
    }
    .active-gnav & {
      display: none;
    }
  }
}

// Overrides for specific sections and states applied to html and body
html.active-gnav {
  .gnav-header-block--hidden .gnav-header-block--sticky .gnav-header-block__inner {
    @include transform(unset);
  }
}

body {
  .active-gnav & {
    max-width: 100%;
    width: 100%;
    position: relative;
  }
  // GNAV simplified on all checkout pages, excluding the cart page.
  &#index,
  &#samples,
  &#shipping,
  &#billing,
  &#review,
  &#confirm {
    .gnav-header-block {
      &__offers,
      &__utilities,
      &__menu-icon {
        display: none;
      }
      &__logo {
        position: relative;
        left: auto;
        margin: 0 auto;
        transform: none;
      }
    }
    .gnav-header-sections {
      display: none;
    }
  }
  &.gnav-util-overlay-active {
    .site-gnav-header-dark-overlay {
      @include breakpoint($landscape-up) {
        opacity: 0.5;
        background: $color-black;
        position: fixed;
        z-index: 9999;
        height: 100%;
        width: 100%;
        #{$ldirection}: 0;
      }
    }
    &.gnav-header-block--hidden {
      .site-gnav-header-dark-overlay {
        @include breakpoint($landscape-up) {
          top: 0;
        }
      }
    }
  }
}
